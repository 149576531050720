<div class="tableFixHead tableFixHead2">
    <div style="background-position:10px 10px" class="inset-0 bg-grid-slate-100 [mask-image:linear-gradient(0deg,#fff,rgba(255,255,255,0.6))] dark:bg-grid-slate-700/25 dark:[mask-image:linear-gradient(0deg,rgba(255,255,255,0.1),rgba(255,255,255,0.5))]"></div>
    <div class="relative bg-white rounded-xl">
        <div class="overflow-x-auto mx-6 mb-6 mt-4" style="max-height: 70vh !important;">
            <table class="border-collapse table-auto w-full text-sm">
                <thead>
                    <tr>
                        
                        <th class="border-b bg-white font-normal pr-2 pl-2 pt-2 pb-2 text-1xl text-blue-700 text-center">
                            Title
                          </th>
                          <th class="border-b bg-white font-normal pr-2 pl-2 pt-2 pb-2 text-1xl  text-blue-700 text-center">
                            Open Date
                          </th>
                          <th class="border-b bg-white font-normal pr-2 pl-2 pt-2 pb-2 text-1xl text-blue-700 text-center">
                            Shift Start
                          </th>
                          <th class="border-b bg-white font-normal pr-2 pl-2 pt-2 pb-2 text-1xl text-blue-700 text-center">
                            Close Date
                          </th>
                          <th class="border-b bg-white font-normal pr-2 pl-2 pt-2 pb-2 text-1xl text-blue-700 text-center">
                            Shift End 
                          </th>
                          <th class="border-b bg-white font-normal pr-2 pl-2 pt-2 pb-2 text-1xl text-blue-700 text-center">
                            Action
                          </th>
                    </tr>
                </thead>
               
                <tbody  class="bg-white mb-24">
                    <tr>
                        <td class="border-b border-blue-100 text-center dark:border-blue-100 p-2 text-ioml dark:text-slate-400">
                            <mat-form-field class="p-0 w-60 bg-white thiscomp">
                                <input matInput class="text-blue-800" [disabled]="true" [(ngModel)]="foir.title" id="title" name="title">
                        </mat-form-field>
                        </td>
                        <td class="border-b border-blue-100 text-center dark:border-blue-100 p-2 text-ioml dark:text-slate-400">
                            <mat-form-field class="p-0 bg-white thiscomp">
                                <!-- <mat-label>Choose Start Date </mat-label> -->
                                <input matInput class="text-blue-800" [matDatepicker]="picker1" [(ngModel)]="foir.start_date_moment" (dateChange)="foirfun($event)">
                                <mat-datepicker-toggle matIconSuffix [for]="picker1"></mat-datepicker-toggle>
                                <mat-datepicker #picker1></mat-datepicker>
                              </mat-form-field>
                        </td>
                        <td class="border-b border-blue-100 text-center dark:border-blue-100 p-2 text-ioml dark:text-slate-400">
                        <mat-form-field class="p-0 w-40 bg-white thiscomp">
                            <!-- <mat-label>Shift Start Time</mat-label> -->
                            <input matInput [format]="24" class="text-blue-800" [ngxMatTimepicker]="pickertd" [(ngModel)]="foir.start_time_moment" />
                            <mat-icon matSuffix
                            (click)="pickertd.open()">
                            </mat-icon>
                            <ngx-mat-timepicker [appendToInput]="true" #pickertd></ngx-mat-timepicker>
                            <mat-icon
                                matSuffix
                                (click)="pickertd.open()"
                                >schedule</mat-icon>
                        </mat-form-field>
                        </td>
                        <td class="border-b border-blue-100 text-center dark:border-blue-100 p-2 text-ioml dark:text-slate-400">
                            <mat-form-field class="p-0 bg-white thiscomp">
                                <!-- <mat-label>Choose End Date </mat-label> -->
                                <input matInput class="text-blue-800" [matDatepicker]="picker2" [(ngModel)]="foir.end_date_moment" (dateChange)="foirfun($event)">
                                <mat-datepicker-toggle matIconSuffix [for]="picker2"></mat-datepicker-toggle>
                                <mat-datepicker #picker2></mat-datepicker>
                              </mat-form-field>
                        </td>
                        <td class="border-b border-blue-100 text-center dark:border-blue-100 p-2 text-ioml dark:text-slate-400">
                        <mat-form-field class="p-0 w-40 bg-white thiscomp">
                            <!-- <mat-label>Shift End Time</mat-label> -->
                            <input matInput [format]="24" class="text-blue-800" [ngxMatTimepicker]="pickerdt" [(ngModel)]="foir.end_time_moment" />
                            <mat-icon matSuffix
                            (click)="pickerdt.open()">
                            </mat-icon>
                            <ngx-mat-timepicker [appendToInput]="true" #pickerdt></ngx-mat-timepicker>
                            <mat-icon
                                matSuffix
                                (click)="pickerdt.open()"
                                >schedule</mat-icon>
                        </mat-form-field>
                        </td>
                       
                        <td class="border-b border-blue-100 dark:border-blue-100 text-center p-2 bg-white text-ioml dark:text-slate-400" >
                            <span>
                                <button id="gbtnactiondefoir" class="mb-6" (click)="fetchRecord()" matTooltip="Search Data"  mat-raised-button>
                                    GO
                                </button>
                            </span> 
                        </td>                                                     
                        </tr>
                </tbody>
            </table>
        </div>
       
        <ng-container *ngFor="let prod of product">
        <div class="flex flex-col pl-8 pr-8 flex-wrap justify-evenly overflow-hidden" *ngIf="foir.data && foir.data.nozzles">
            <div class="text-blue-900 font-bold mb-3 text-2xl">PRODUCT: {{cs.producthash[prod]? cs.producthash[prod].product_name: prod}}</div>
            <div class="flex flex-col flex-wrap items-stretch mb-8 overflow-hidden sm:overflow-y-auto" >
                <ng-container>
                        <div class="grid overflow-x-auto">
                            <div class="inventory-gridss z-10 sticky top-0 grid gap-10 py-4 items-center text-center px-6 md:px-8 shadow text-md font-semibold text-secondary bg-gray-50 dark:bg-card"
                                >
                                    <div>
                                        DU_PUMP_NOZZLE
                                    </div>
                                    <div>
                                        CURRENT TOTALIZER
                                    </div>
                                    <div>
                                        PREVIOUS TOTALIZER
                                    </div>
                                    <div>
                                        NET NOZZLE SALES
                                    </div>
                            </div>
                            <ng-container *ngFor="let trans of foir.data.nozzles;">
                                <div *ngIf="trans.product_code == prod" class="inventory-gridss grid items-center text-center bg-blue-50 gap-10 py-3 px-6 md:px-8 border-b border-blue-200">
                                    <div *ngFor="let head of header1;let i = index" >
                                    <div  class="text-blue-800" *ngIf="head.column !== 'total_volume'">
                                        {{trans[head.column]}}
                                    </div>
                                    <div  class="text-blue-900 font-semibold" *ngIf="head.column === 'total_volume'">
                                        {{trans[head.column] | number : '1.2-2'}}
                                    </div>
                                </div>
                                </div>
                               
                            </ng-container>
                        </div>
                    </ng-container>
            </div>
        </div>
    </ng-container>

    <div class="flex flex-col pl-8 pr-8 flex-wrap justify-evenly overflow-hidden" *ngIf="foir.data && foir.data.nozzle_Sales">
        <div class="text-blue-900 font-bold mb-3 text-2xl">NOZZLE SALES SUMMARY (IN LTR.)</div>
        <div class="flex flex-col flex-wrap items-stretch mb-8 overflow-hidden sm:overflow-y-auto" >
            <ng-container>
                    <div class="grid overflow-x-auto">
                        <div class="inventory-gridsts z-10 sticky top-0 grid gap-10 py-4 items-center text-center px-6 md:px-8 shadow text-md font-semibold text-secondary bg-gray-50 dark:bg-card"
                            >
                            <div *ngFor="let head of header2;let i = index" >
                                <div>
                                    {{head.header}}
                                </div>
                                </div>
                                
                        </div>
                        <ng-container *ngFor="let noz of foir.data.nozzle_Sales;">
                            <div class="inventory-gridsts grid items-center text-center bg-blue-50 gap-10 py-3 px-6 md:px-8 border-b border-blue-200">
                                <div *ngFor="let head of header2;let i = index" >
                                <div *ngIf="head.type == 'text'" class="text-blue-800">
                                    {{noz[head.column]}}
                                </div>
                                <div *ngIf="head.type == 'number'" class="text-blue-800">
                                    {{noz[head.column] | number : '1.2-2'}}
                                </div>
                                <div *ngIf="head.type == 'product'" class="text-blue-800">
                                    {{noz[head.column]? cs.producthash[noz[head.column]]? cs.producthash[noz[head.column]].product_name: noz[head.column]: noz[head.column]}}
                                </div>

                            </div>
                            </div>
                           
                        </ng-container>
                    </div>
                </ng-container>
        </div>
    </div>

    <div class="flex flex-col pl-8 pr-8 flex-wrap justify-evenly overflow-hidden" *ngIf="foir.data && foir.data.tanks">
        <div class="text-blue-900 font-bold mb-3 text-2xl">TANK STOCK SUMMARY</div>
        <div class="flex flex-col flex-wrap items-stretch mb-8 overflow-hidden sm:overflow-y-auto" >
            <ng-container>
                    <div class="grid overflow-x-auto">
                        <div class="inventory-gridstt z-10 sticky top-0 grid gap-10 py-4 items-center text-center px-6 md:px-8 shadow text-md font-semibold text-secondary bg-gray-50 dark:bg-card"
                            >
                            <div *ngFor="let head of header3;let i = index" >
                                <div>
                                    {{head.header }}
                                </div>
                                </div>
                                
                        </div>
                        <ng-container *ngFor="let noz of foir.data.tanks;">
                            <div class="inventory-gridstt grid items-center text-center bg-blue-50 gap-10 py-3 px-6 md:px-8 border-b border-blue-200">
                                <div *ngFor="let head of header3;let i = index" >
                                <div *ngIf="head.type == 'text'" class="text-blue-800">
                                    {{noz[head.column]}}
                                </div>
                                <div *ngIf="head.type == 'product'" class="text-blue-800">
                                    {{noz[head.column]? cs.producthash[noz[head.column]]? cs.producthash[noz[head.column]].product_name: noz[head.column]: noz[head.column]}}
                                </div>
                                <div *ngIf="head.type == 'number'" class="text-blue-800">
                                    {{noz[head.column] | number : '1.2-2'}}
                                </div>
                                
                            </div>
                            </div>
                           
                        </ng-container>
                    </div>
                </ng-container>
        </div>
    </div>

    <div class="flex flex-col pl-8 pr-8 flex-wrap justify-evenly overflow-hidden" *ngIf="foir.data && foir.data.tanks">
        <div class="text-blue-900 font-bold mb-3 text-2xl">STOCK SUMMARY</div>
        <div class="flex flex-col flex-wrap items-stretch mb-8 overflow-hidden sm:overflow-y-auto" >
            <ng-container>
                    <div class="grid overflow-x-auto">
                        <div class="inventory-gridstt z-10 sticky top-0 grid gap-10 py-4 items-center text-center px-6 md:px-8 shadow text-md font-semibold text-secondary bg-gray-50 dark:bg-card"
                            >
                            <div *ngFor="let head of header4;let i = index" >
                                <div>
                                    {{head.header }}
                                </div>
                                </div>
                                
                        </div>
                        <ng-container *ngFor="let noz of foir.data.tank_Sales;">
                            <div class="inventory-gridstt grid items-center text-center bg-blue-50 gap-10 py-3 px-6 md:px-8 border-b border-blue-200">
                                <div *ngFor="let head of header4;let i = index" >
                                <div *ngIf="head.type == 'text'" class="text-blue-800">
                                    {{noz[head.column]}}
                                </div>
                                <div *ngIf="head.type == 'number'" class="text-blue-800">
                                    {{noz[head.column] | number : '1.2-2'}}
                                </div>
                                <div *ngIf="head.type == 'product'" class="text-blue-800">
                                    {{noz[head.column]? cs.producthash[noz[head.column]]? cs.producthash[noz[head.column]].product_name: noz[head.column]: noz[head.column]}}
                                </div>

                            </div>
                            </div>
                           
                        </ng-container>
                    </div>
                </ng-container>
        </div>
    </div>

    <div *ngIf="foir && foir.data && foir.data.permisible_products && foir.data.permisible_products.length>0 " class="flex flex-col ml-8 mr-8 bg-gray-50 flex-wrap justify-evenly overflow-hidden">
        <div class="text-green-900 font-bold mb-3 text-2xl">STOCK VARIATION IS WITHIN PERMISSIBLE LIMITS FOR {{foir.data.permisible_products}}</div>
    </div>
    <div *ngIf="foir && foir.data && foir.data.not_permisible_products && foir.data.not_permisible_products.length>0" class="flex flex-col ml-8 mr-8 bg-gray-50 flex-wrap justify-evenly overflow-hidden">
        <div class="text-red-900 font-bold mb-3 text-2xl">STOCK VARIATION IS NOT WITHIN PERMISSIBLE LIMITS FOR {{foir.data.not_permisible_products}}</div>
    </div>
    <div class="flex flex-col pl-8 pr-8 mt-4 flex-wrap justify-evenly overflow-hidden" *ngIf="foir && foir.pvc">
        <div class="text-blue-900 font-bold mb-3 text-2xl">PERMISSIBLE VARIATION CALCULATION</div>
        <div class="flex flex-col flex-wrap items-stretch mb-8 overflow-hidden sm:overflow-y-auto" >
            <ng-container>
                    <div class="grid overflow-x-auto">
                        <div class="inventory-gridssf z-10 sticky top-0 grid gap-10 py-4 items-center text-center px-6 md:px-8 shadow text-md font-semibold text-secondary bg-gray-50 dark:bg-card"
                            >
                            <div *ngFor="let head of header5;let i = index" >
                                <div>
                                    {{head.header }}
                                </div>
                                </div>
                                
                        </div>
                        <ng-container *ngFor="let noz of foir.pvc;">
                            <div class="inventory-gridssf grid items-center text-center bg-blue-50 gap-10 py-3 px-6 md:px-8 border-b border-blue-200">
                                <div *ngFor="let head of header5;let i = index" >
                                <div *ngIf="head.type == 'text'" class="text-blue-800">
                                    {{noz[head.column]}}
                                </div>
                                <div *ngIf="head.type == 'number'" class="text-blue-800">
                                    {{noz[head.column] | number : '1.2-2'}}
                                </div>
                                <div *ngIf="head.type == 'product'" class="text-blue-800">
                                    {{noz[head.column]? cs.producthash[noz[head.column]]? cs.producthash[noz[head.column]].product_name: noz[head.column]: noz[head.column]}}
                                </div>

                            </div>
                            </div>
                           
                        </ng-container>
                    </div>
                </ng-container>
        </div>
    </div>

    <div class="flex flex-col pl-8 pr-8 flex-wrap justify-evenly overflow-hidden">
        <div class="text-blue-900 font-bold mb-3 text-2xl">PERMISSIBLE VARIATION CALCULATION</div>
        <div class="flex flex-col flex-wrap items-stretch mb-8 overflow-hidden sm:overflow-y-auto" >
            <ng-container>
                    <div class="grid overflow-x-auto">
                        <div class="z-10 sticky top-0 grid gap-10 py-4 items-center text-center px-6 md:px-8 shadow text-md font-semibold text-secondary bg-gray-50 dark:bg-card"
                            >
                                <div>
                                    METHOD FOR CALCULATIONG THE PER-PRODUCT PERMISSION VARIATION
                                </div>
                                
                        </div>
                        <ng-container>
                            <div class="grid items-center text-center bg-blue-50 px-6 md:px-8 border-b border-blue-200">
                                
                                <div class="text-blue-800 border-b p-2 border-blue-200">
                                    D = Number of days between inspection
                                </div>
                                <div class="text-blue-800 border-b p-2 border-blue-200">
                                    S = Nozzle sales
                                </div>
                                <div class="text-blue-800 border-b p-2 border-blue-200">
                                    X = D/365*600000 (sales volume for calculation)
                                </div>
                                <div class="text-blue-800 border-b p-2 border-blue-200">
                                    PV = Permissible variation
                                </div>
                                <div class="text-blue-800 border-b p-2 border-blue-200">
                                    P1
                                </div>
                                <div class="text-blue-800 border-b p-2 border-blue-200">
                                    * MS = MIN(X,S)*0.75/100
                                </div>
                                <div class="text-blue-800 border-b p-2 border-blue-200">
                                    * HS = MIN(X,S)*0.25/100
                                </div>
                                <div class="text-blue-800 border-b p-2 border-blue-200">
                                    P2
                                </div>
                                <div class="text-blue-800 border-b p-2 border-blue-200">
                                    * MS = if(S>X, (0.6/100(S-X)), 0)
                                </div>
                                <div class="text-blue-800 border-b p-2 border-blue-200">
                                    * HS = if(S>X, (0.2/100(S-X)), 0)
                                </div>
                                <div class="text-blue-800 border-b p-2 border-blue-200">
                                    T = 4% of closing stock (by DIP)
                                </div>
                                <div class="text-blue-800 border-b p-2 border-blue-200">
                                    PV = IF(variation < 0, (P1+P2+T), T)
                                </div>
                                </div>
                        </ng-container>
                    </div>
                </ng-container>
        </div>
    </div>


       </div>
    </div>
